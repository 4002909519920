<template>
  <v-tab
    :ripple="false"
    :class="[
      'tab-style',
      noPadding ? 'zg-inner-tab-no-padding' : 'zg-inner-tab-padding',
    ]"
  >
    <v-layout
      fill-height
      align-center
      justify-center
      :class="[active ? 'zg-inner-tab-active' : 'zg-inner-tab-inactive']"
    >
      <v-layout shrink justify-start>
        <v-layout column :class="['text-center']">
          <v-flex>
            <div
              :style="{ color: active ? '#2a2c32' : '#7C7E8B' }"
              :class="['text-subtitle-1 mx-1 zg-tab-text', active && 'active']"
            >
              {{ value }}
            </div>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-layout>
  </v-tab>
</template>

<script>
export default {
  props: {
    value: {},
    active: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.zg-tab-text {
  font-weight: 500;

  &.active {
    font-weight: 600;
  }
}
.tab-style.v-tab--active:hover::before {
  opacity: 0 !important;
}
.zg-inner-tab-active {
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  padding-top: 4px;
  padding-left: 8px;
  padding-right: 8px;
}
.zg-inner-tab-inactive {
  height: 100%;
  width: 100%;
  padding-top: 8px;
  background-color: transparent;
  box-shadow: inset 0px -8px 8px -8px rgba(0, 0, 0, 0.1);
  padding-left: 8px;
  padding-right: 8px;
}
.zg-inner-tab-padding {
  padding-top: 6px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.zg-inner-tab-no-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>